exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-form-tsx": () => import("./../../../src/pages/contact/form.tsx" /* webpackChunkName: "component---src-pages-contact-form-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-photos-image-sharp-parent-file-internal-content-digest-tsx": () => import("./../../../src/pages/photos/{ImageSharp.parent__(File)__internal__contentDigest}.tsx" /* webpackChunkName: "component---src-pages-photos-image-sharp-parent-file-internal-content-digest-tsx" */),
  "component---src-pages-photos-tsx": () => import("./../../../src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */)
}

